import { useState } from 'react'
import emailjs from 'emailjs-com'
import map from "../assets/map.png"

const initialState = {
  subject:'Kullanıcı mesajı',
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message, subject }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_8f9bwrj', 'template_huv5atk', e.target, 'user_lCBiS5DTZcZDistQnphfw'
      )
      .then(
        (result) => {
          alert("Mesajınız İletildi. En kısa zamanda sizinle iletişime geçilecektir.")
          clearState()
        },
        (error) => {

        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Bize Ulaşın</h2>
            
              </div>
          
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Adınız'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mesajınız'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Gönder
                </button>
              </form>
            </div>
         
       
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>İletişim Bilgileri</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Adres
                </span>
                Yakuplu mah.Hürriyet Bulvarı 85.sok No:3/A Kat:3 Beylikdüzü / İSTANBUL
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefon
                </span>{' '}
                0212 875 40 00
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Fax
                </span>{' '}
                0212 875 40 03
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                info@safirjakar.com
              </p>
            </div>
          </div>
          <div  className='col-md-12'>
              <img 
                src={map}
                style={{
                  width:'100%',
                  height:"300px",
                  objectFit:"cover"
                }}
              />
            </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a target="blank" href={"https://www.instagram.com/safirjakartekstil/"}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a target="blank" href={"https://www.youtube.com/channel/UC1A_j1M1-2rjNt-zh2G0lnw"}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>
  )
}
