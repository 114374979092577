import ServicesData from "../json/Services";

export const Services = (props) => {

  const style= {
    width: "300px",
    height: "300px",
    background: "linearGradient(to right, #00B0B7 0%, #5ca9fb 100%)",
    borderRadius: "180px",
    color: "#fff",
    boxShadow: "10px 10px 10px rgb(0 0 0 / 5%)",
    padding:"25px"
  }

  const style2= {
    width: "250px",
    height: "250px",
    background: "linearGradient(to right, #00B0B7 0%, #5ca9fb 100%)",
    borderRadius: "150px",
    objectFit:"cover"
  }

  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Üretim</h2>
       
        </div>
        <div className='row justify-content-center align-items-center'>
          {
             ServicesData.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 justify-content-center align-items-center'>
                  {' '}
                  <div style={style}>
                    <img src={d.img}  style={style2} />
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  )
}



