import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import "./App.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MyBook from "./components/books";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

{
  /* <div >
<Navigation />
<Header />
<About />
<Services />
<Gallery />
<Contact />

</div> */
}

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <div>
            <Navigation />
            <Header />
            <About />
            <Services />
            <Gallery />
            <Contact />
          </div>
        </Route>
        <Route path="/katalog">
          <MyBook />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
