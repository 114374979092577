import { Link } from "react-router-dom"
import image from "../assets/logo.PNG"
export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
        <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
            <img 
              style={{
                height:"100px",
                width:"240px",
                objectFit:"contain",
              }}
              src={image}
            />
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            {/* <li>
              <a href='#features' className='page-scroll'>
                Features
              </a>
            </li> */}
            <li>
              <a href='#about' className='page-scroll'>
                Hakkımızda
              </a>
            </li>
            <li>
          
              <Link to="/katalog" className='page-scroll'>Katalog</Link>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Üretim 
              </a>
            </li>
            <li>
              <a href='#portfolio' className='page-scroll'>
                Ürünler
              </a>
            </li>
            {/* <li>
              <a href='#testimonials' className='page-scroll'>
                Referanslar
              </a>
            </li> */}
            {/* <li>
              <a href='#team' className='page-scroll'>
                Team
              </a>
            </li> */}
            <li>
              <a href='#contact' className='page-scroll'>
                İletişim
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}