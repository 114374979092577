import imgOne from "../assets/production/IMG_0349.png";
import imgTwo from "../assets/production/IMG_0350.png";
import imgThree from "../assets/production/IMG_0357.png";
import imgFour from "../assets/production/IMG_0358.png";
import imgFive from "../assets/production/IMG_0359.png";
import imgSix from "../assets/production/IMG_0361.png";
import imgSeven from "../assets/production/IMG_0431.png";
import imgEight from "../assets/production/IMG_0830.png";

export default [
    {
      "img": imgOne,
      "name": "Lorem ipsum dolor",
      "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
    },
    {
      "img": imgTwo,
      "name": "Consectetur adipiscing",
      "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
    },
    {
      "img": imgThree,
      "name": "Lorem ipsum dolor",
      "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
    },
    {
      "img": imgFour,
      "name": "Consectetur adipiscing",
      "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
    },
    {
      "img": imgFive,
      "name": "Lorem ipsum dolor",
      "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
    },
    {
      "img": imgSix,
      "name": "Consectetur adipiscing",
      "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
    },
    {
      "img": imgSeven,
      "name": "Lorem ipsum dolor",
      "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
    },
    {
      "img": imgEight,
      "name": "Consectetur adipiscing",
      "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
    }
  ]
