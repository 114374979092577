import imgOne from "../assets/product/r.png";
import imgTwo from "../assets/product/çocuk_2.png";
import imgThree from "../assets/product/çocuk_3.png";
import imgFour from "../assets/product/çocuk_4.png";
import imgFive from "../assets/product/çocuk_lcw.png";
import imgSix from "../assets/product/IMG_0707.png";
import imgSeven from "../assets/product/IMG_0708.png";
import imgEight from "../assets/product/IMG_0709.png";
import imgNine from "../assets/product/IMG_0710.png";
import imgTen from "../assets/product/IMG_0711.png";
import imgEOne from "../assets/product/IMG_0712.png";
import imgETwo from "../assets/product/IMG_0713.png";

export default [
  {
    largeImage: imgOne,
    smallImage: imgOne,
  },
  {
    largeImage: imgTwo,
    smallImage: imgTwo,
  },
  {
    largeImage: imgThree,
    smallImage: imgThree,
  },
  {
    largeImage: imgFour,
    smallImage: imgFour,
  },
  {
    largeImage: imgFive,
    smallImage: imgFive,
  },
  {
    largeImage: imgSix,
    smallImage: imgSix,
  },
  {
    largeImage: imgSeven,
    smallImage: imgSeven,
  },
  {
    largeImage: imgEight,
    smallImage: imgEight,
  },
  {
    largeImage: imgNine,
    smallImage: imgNine,
  },
  {
    largeImage: imgTen,
    smallImage: imgTen,
  },
  {
    largeImage: imgEOne,
    smallImage: imgEOne,
  },
  {
    largeImage: imgETwo,
    smallImage: imgETwo,
  },
];
