import aboutJson from "../json/About.json";

export const About = (props) => {
  return (
    <div id="about">


      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-7">
            {" "}
            <div style={{width:"100%", height:400}} >
          
              <iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="100%" height="100%" src="https://www.youtube.com/embed/rEJZg8JmcUE?autoplay=0&amp;mute=0&amp;controls=0&amp;origin=http%3A%2F%2Flocalhost%3A3000&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=1" id="widget2"></iframe>
            </div>
           
          </div>
          <div className="col-xs-12 col-md-5">
            <div className="about-text">
              <h2>Hakkımızda</h2>
              <p>{aboutJson.About.paragraph}</p>
              <p>{aboutJson.About.paragraphOne}</p>
              <p>{aboutJson.About.paragraphTwo}</p>
              <p>{aboutJson.About.paragraphThree}</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {aboutJson.About.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                  {aboutJson.About.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
