import { Link } from "react-router-dom"
import React from "react"
export const Header = (props) => {


  return (
    <header id='header'>
      <div className='intro' style={{width:"100%"}} >
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
              <Link to="/katalog" className='btn btn-custom btn-lg page-scroll'>Ürün Kataloğu</Link>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
