import HTMLFlipBook from "react-pageflip";
import img1 from "../assets/pages/1.jpg";
import img2 from "../assets/pages/2.jpg";
import img3 from "../assets/pages/3.jpg";
import img4 from "../assets/pages/4.jpg";
import img5 from "../assets/pages/5.jpg";
import img6 from "../assets/pages/6.jpg";
import img7 from "../assets/pages/7.jpg";
import img8 from "../assets/pages/8.jpg";
import img9 from "../assets/pages/9.jpg";
import img10 from "../assets/pages/10.jpg";
import img11 from "../assets/pages/11.jpg";
import img12 from "../assets/pages/12.jpg";
import img13 from "../assets/pages/13.jpg";
import img14 from "../assets/pages/14.jpg";

const { innerWidth, innerHeight } = window;

function MyBook(props) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingLeft: innerWidth / 10,
        paddingTop: (innerHeight - (innerWidth / 2.5 + 150)) / 2,
      }}
    >
      <HTMLFlipBook width={innerWidth / 2.5} height={innerWidth / 2.5 + 150}>
        <div className="demoPage">
          <img style={{ width: "100%", height: "100%" }} src={img1} />
        </div>
        <div className="demoPage">
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={img2} />
        </div>
        <div className="demoPage">
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={img3} />
        </div>
        <div className="demoPage">
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={img4} />
        </div>

        <div className="demoPage">
          <img style={{ width: "100%", height: "100%" }} src={img5} />
        </div>
        <div className="demoPage">
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={img6} />
        </div>
        <div className="demoPage">
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={img7} />
        </div>
        <div className="demoPage">
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={img8} />
        </div>
        <div className="demoPage">
          <img style={{ width: "100%", height: "100%" }} src={img9} />
        </div>
        <div className="demoPage">
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={img10} />
        </div>
        <div className="demoPage">
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={img11} />
        </div>
        <div className="demoPage">
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={img12} />
        </div>
        <div className="demoPage">
          <img style={{ width: "100%", height: "100%" }} src={img13} />
        </div>
        <div className="demoPage">
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={img14} />
        </div>
      </HTMLFlipBook>
    </div>
  );
}

export default MyBook;
